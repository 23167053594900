<template>
	<v-container fill-height>
		<div id="signature-pad" class="signature-pad">
			<div class="signature-pad--body">
				<canvas ref="signature"></canvas>
			</div>
			<div class="signature-pad--footer">
				<div class="description">Sign above</div>

				<div class="signature-pad--actions">
					<div class="column">
						<v-btn @click="clearSign">Clear</v-btn>
					</div>
					<div class="column">
						<v-btn @click="saveSign"> Save as PNG </v-btn>
					</div>
				</div>
			</div>
		</div>
	</v-container>
</template>
<script>
import SignaturePad from "signature_pad";

export default {
	data() {
		return { signatureObj: null };
	},
	created() {
		window.addEventListener("resize", this.handleResize);
	},
	destroyed() {
		window.removeEventListener("resize", this.handleResize);
	},
	methods: {
		clearSign() {
			this.signatureObj.clear();
		},
		download(dataURL, filename) {
			const blob = this.dataURLToBlob(dataURL);
			const url = window.URL.createObjectURL(blob);

			const a = document.createElement("a");
			a.style = "display: none";
			a.href = url;
			a.download = filename;

			document.body.appendChild(a);
			a.click();

			window.URL.revokeObjectURL(url);
		},
		dataURLToBlob(dataURL) {
			const parts = dataURL.split(";base64,");
			const contentType = parts[0].split(":")[1];
			const raw = window.atob(parts[1]);
			const rawLength = raw.length;
			const uInt8Array = new Uint8Array(rawLength);

			for (let i = 0; i < rawLength; ++i) {
				uInt8Array[i] = raw.charCodeAt(i);
			}

			return new Blob([uInt8Array], { type: contentType });
		},
		handleResize() {
			// When zoomed out to less than 100%, for some very strange reason,
			// some browsers report devicePixelRatio as less than 1
			// and only part of the canvas is cleared then.
			const ratio = Math.max(window.devicePixelRatio || 1, 1);
			let canvas = this.$refs.signature;
			// This part causes the canvas to be cleared
			canvas.width = canvas.offsetWidth * ratio;
			canvas.height = canvas.offsetHeight * ratio;
			canvas.getContext("2d").scale(ratio, ratio);

			// This library does not listen for canvas changes, so after the canvas is automatically
			// cleared by the browser, SignaturePad#isEmpty might still return false, even though the
			// canvas looks empty, because the internal data of this library wasn't cleared. To make sure
			// that the state of this library is consistent with visual state of the canvas, you
			// have to clear it manually.
			//signaturePad.clear();

			// If you want to keep the drawing on resize instead of clearing it you can reset the data.
			this.signatureObj.fromData(this.signatureObj.toData());
		},
		saveSign() {
			console.log("save");
			if (this.signatureObj.isEmpty()) {
				alert("Please provide a signature first.");
			} else {
				const dataURL = this.signatureObj.toDataURL();
				this.download(dataURL, "signature.png");
			}
		},
	},
	mounted() {
		let canvas = this.$refs.signature;
		this.signatureObj = new SignaturePad(canvas, {
			// It's Necessary to use an opaque color when saving image as JPEG;
			// this option can be omitted if only saving as PNG or SVG
			backgroundColor: "rgb(255, 255, 255)",
		});
		this.handleResize();
	},
};
</script>
<style scoped>
.signature-pad {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	font-size: 10px;
	width: 100%;
	height: 100%;
	max-width: 700px;
	max-height: 460px;
	border: 1px solid #e8e8e8;
	background-color: #fff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27),
		0 0 40px rgba(0, 0, 0, 0.08) inset;
	border-radius: 4px;
	padding: 16px;
}

.signature-pad::before,
.signature-pad::after {
	position: absolute;
	z-index: -1;
	content: "";
	width: 40%;
	height: 10px;
	bottom: 10px;
	background: transparent;
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
}

.signature-pad::before {
	left: 20px;
	-webkit-transform: skew(-3deg) rotate(-3deg);
	transform: skew(-3deg) rotate(-3deg);
}

.signature-pad::after {
	right: 20px;
	-webkit-transform: skew(3deg) rotate(3deg);
	transform: skew(3deg) rotate(3deg);
}

.signature-pad--body {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border: 1px solid #f4f4f4;
}

.signature-pad--body canvas {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	border-radius: 4px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.signature-pad--footer {
	color: #c3c3c3;
	text-align: center;
	font-size: 1.2em;
	margin-top: 8px;
}

.signature-pad--actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-top: 8px;
}

.signature-pad--actions .column {
	padding: 0.1em;
}

.signature-pad--actions .button {
	margin: 0.2em;
}
</style>
